@font-face {
    font-family: 'GothamRegular';
    src: url('assets/fonts/GothamProRegular.woff');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'GothamSemibold';
    src: url('assets/fonts/GothamProMedium.woff');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'GothamBold';
    src: url('assets/fonts/GothamProBold.woff');
    font-style: normal;
    font-weight: normal;
}